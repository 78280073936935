import LocaleWrap from "./wrapPageElement"
export const wrapPageElement = LocaleWrap

export const onClientEntry = () => {
    const script = document.createElement("script");
    script.id = "Cookiebot";
    script.src = "https://consent.cookiebot.com/uc.js";
    script.setAttribute("data-cbid", "02367b38-5593-4b8f-a59a-0c3fed99fdc7");
    script.setAttribute("data-blockingmode", "auto");
    script.type = "text/javascript";
    document.body.appendChild(script);
};