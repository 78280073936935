import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import reset from './reset';
import { defaultTheme } from './theme';

const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    vertical-align: baseline;
    font-family: inherit;
    font-style: inherit;
    font-size: 100%;
    border: none;
    padding: 0;
    margin: 0;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* overflow: hidden; */
    /* scroll-behavior: smooth; */
  }
  body {
    color: ${theme.colors.primary};
    /* scroll-behavior: smooth; */
    /* overflow: hidden; */
    font-family: ${theme.fontFamily.primary};
    width: 100%;
  }

  canvas {
    min-height: 100vh;
  }

  ${defaultTheme};
`;

export default GlobalStyle;
