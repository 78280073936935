/* eslint react/prop-types: 0, react/display-name: 0 */
import React from 'react';
import GlobalStyle from './src/utils/styles/global';
import theme from './src/utils/styles/theme';
import { ThemeProvider } from 'styled-components';
import moduleName from './src/utils/styles/sass/app.sass';
import Menu from 'components/Menu';
// import SEO from 'components/SEO';

const wrapPageElement = ({ element, props }) => {
  // const node = props?.data?.allContentfulPage?.edges?.[0]?.node;

  const pathname = props?.location?.pathname;
  let isDarkMode = false;

  if (pathname.includes('evenement')) {
    isDarkMode = true;
  }

  return (
    <>
      {/* <SEO
        {...node?.seoMeta}
        components={node?.components}
        location={props?.location}
        locale={props?.pageContext?.locale}
        tags={props?.pageContext?.tags}
        pageContext={props?.pageContext}
      /> */}
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Menu isDarkMode={isDarkMode} location={props.location} />
        <main>{element}</main>
      </ThemeProvider>
    </>
  );
};

export default wrapPageElement;
