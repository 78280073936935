import styled from 'styled-components';
import { motion } from 'framer-motion';
import Typography from 'components/UI/Typography';

export const HamburgerMenu = styled.div`
  position: fixed;
  right: 25px;
  top: 27px;
  width: 25px;
  height: 18px;
  z-index: 12;
  cursor: pointer;

  &:hover {
    > div {
      background: ${(props) => props.theme.colors.secondary};
    }
  }
`;

export const HamburgerMenuBar = styled(motion.div)`
  width: 100%;
  height: 1px;
  background: ${(props) => props?.isLight ? props.theme.colors.primary : props.theme.colors.purple};
  pointer-events: none;
  position: absolute;
`;

export const MenuNavigation = styled(motion.nav)`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: ${(props) => (props.navOpen ? 'initial' : 'none')};
`;

export const MenuNavigationContent = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const MenuNavigationBackground = styled(motion.div)`
  background: ${(props) => props.theme.colors.blue};
  width: 100%;
  height: 100%;
  transform-origin: top;
  opacity: 0;
  text-align: center;
`;

export const MenuItem = styled(Typography)`
  font-size: ${(props) => props.theme.fonts.h1_l};
  font-weight: 300;
  color: ${(props) => props.theme.colors.secondary};
  cursor: pointer;
  transition: color 300ms ease;
  
  svg {
    transition: fill 300ms ease;
  }

  :hover {
    color: ${(props) => props.theme.colors.primary};

    svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const MenuItemContainer = styled(motion.div)`
  :not(:last-of-type) {
    margin-bottom: 25px;
  }
`;
