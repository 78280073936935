// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-aide-js": () => import("./../../../src/pages/aide.js" /* webpackChunkName: "component---src-pages-aide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partenaires-js": () => import("./../../../src/pages/partenaires.js" /* webpackChunkName: "component---src-pages-partenaires-js" */),
  "component---src-pages-peuples-autochtones-js": () => import("./../../../src/pages/peuples-autochtones.js" /* webpackChunkName: "component---src-pages-peuples-autochtones-js" */),
  "component---src-templates-background-events-js": () => import("./../../../src/templates/background-events.js" /* webpackChunkName: "component---src-templates-background-events-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */)
}

